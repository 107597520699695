<template>
  <div class="auth-wrapper auth-v1 px-2 bg-login">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 bg-gradient-black">
        <b-link class="brand-logo mb-1 mt-0">
          <b-img
            :src="require('@/assets/images/erp/logo.png')"
            width="350"
          />
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="usuario"
              label="Usuario"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuario"
                rules="required"
              >
                <b-form-input
                  id="usuario"
                  v-model="userEmail"
                  name="login-usuario"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Usuario"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
                <!-- <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Olvidaste tu contraseña?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Recordarme
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Ingresar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  /* BFormCheckbox, */
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import store from '@/store/index'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser, getAbilityByRol } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    /* BFormCheckbox, */
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      token: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    async login() {
      await store
        .dispatch('auth/AUTH_CREATE_TOKEN_PI', {
          password: '123456xx',
          username: 'machine@visitasanmartin.pe',
        })
        .then(due => {
          localStorage.setItem('tokenPI', due.token)
        })
        .catch(error => {
          console.log(error)
        })
      await store
        .dispatch('auth/AUTH_CREATE_TOKEN', {
          username: this.userEmail,
          password: this.password,
        })
        .then(async response => {
          if (response) {
            this.token = response.token
            const { user } = response
            useJwt.setToken(response.token)
            useJwt.setRefreshToken(response.token)
            user.ability = getAbilityByRol(user.perfil.nombre)
            localStorage.setItem('userData', JSON.stringify(user))
            localStorage.setItem('userProfile', JSON.stringify(user.perfil))
            this.$ability.update([{ action: 'manage', subject: 'all' }])

            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 5)

            this.$router
              .push(getHomeRouteForLoggedInUser(user.perfil.nombre))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Bienvenido ${user.nombreCompleto}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Has iniciado sesión con éxito como ${user.perfil.nombre}. Ahora puedes empezar a trabajar!`,
                  },
                })
              })
              .catch(error => {
                this.$refs.loginForm.setErrors(error.response.data.error)
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Acceso no Autorizado',
                icon: 'LockIcon',
                variant: 'danger',
                text:
                  'Lo sentimos no tienes ningún perfil asignado para acceder a la plataforma.',
              },
            })
          }
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.errorMessage
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ha ocurrido un error',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: errorMessage,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.bg-login {
  background-image: url('~@/assets/images/erp/fondo-login.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
label {
  /* color: #ebe9f1!important; */
}
.bg-gradient-black {
  /* background: #000000c4; */
}
</style>
